@charset "utf-8";
@import "../node_modules/bulma/sass/utilities/initial-variables";

$card-shadow: none;
$card-header-shadow: none;
$card-background-color: transparent;

@import "../node_modules/bulma/bulma";

.image.is-4by3,.image.is-16by9 {
    overflow:hidden;
    img {
        height: auto;
    }
}
